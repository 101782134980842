import React, { Component } from 'react';

import "./ServiceSection.css";

export default class ServiceSection extends Component {
    render() {
      return (
        <section className="servicesection">     
            <a id="service" href="."> </a>            
            <h1>Service</h1> 
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <h2>Anfahrt</h2>       
                        <div className="map">                             
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2527.667765043701!2d11.16719521574001!3d50.68899167950891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a3fa7f20612711%3A0xa9ff8833394022f3!2sBahn-Hofladen%20e.G.!5e0!3m2!1sde!2sde!4v1682525651905!5m2!1sde!2sde" 
                                    className="map" title="map" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>                                     
                        </div>   
                    </div>                                       
                    <div className="col-sm">
                        <h2>Links</h2>
                        <ul>
                            <li><a href="https://www.xn--knigsee-rottenbach-d3b.de/">Stadt Königsee</a></li>
                            <li><strong>Anreise Auto</strong>
                                <ul>
                                    <li><a href="https://www.google.com/maps/dir//Bahn-Hofladen+eG,+Am+Bahnhof+3,+OT,+07422+Rottenbach/@50.68917,11.1685762,302m/data=!3m1!1e3!4m9!4m8!1m0!1m5!1m1!1s0x47a3fa7f20612711:0xa9ff8833394022f3!2m2!1d11.17016!2d50.68917!3e0">Routenplaner</a></li>
                                </ul>
                            </li>
                            <li><strong>Anreise Bahn</strong>
                                <ul>
                                <li><a href="https://www.google.com/maps/dir//Bahn-Hofladen+eG,+Am+Bahnhof+3,+OT,+07422+Rottenbach/@50.6891489,11.099948,13399m/data=!3m2!1e3!4b1!4m9!4m8!1m0!1m5!1m1!1s0x47a3fa7f20612711:0xa9ff8833394022f3!2m2!1d11.17016!2d50.68917!3e3">Routenplaner</a></li>
                                    <li><a href="https://www.bahn.de">Deutsche Bahn</a></li>
                                    <li><a href="https://iris.noncd.db.de/wbt/js/index.html?bhf=URTB">Bahnhofstafel<br/>Haltestelle Rottenbach</a></li>
                                </ul>
                            </li>
                        </ul> 
                    </div>
                    <div className="col-sm">
                        <h2>Öffnungszeiten</h2> 
                        <table className="table table-striped">
                            <thead>
                                <tr><th>&nbsp;</th><th>Sommerzeit</th><th>Winterzeit</th></tr>
                            </thead>
                            <tbody>                                                               
                                <tr><td>Montag</td><td colSpan="2" className="weekendOpen">11:00–18:00</td></tr>
                                <tr><td>Dienstag</td><td colSpan="2" className="weekendOpen">09:00–18:00</td></tr>
                                <tr><td>Mittwoch</td><td colSpan="2" className="weekendOpen">09:00–18:00</td></tr>
                                <tr><td>Donnerstag</td><td colSpan="2" className="weekendOpen">09:00–18:00</td></tr>
                                <tr><td>Freitag</td><td colSpan="2" className="weekendOpen">09:00–18:00</td></tr>
                                <tr><td>Samstag</td><td colSpan="2" className="weekendOpen">09:00–17:00</td></tr>
                                <tr><td>Sonntag</td><td colSpan="2" className="weekendOpen">14:00–17:00</td></tr>
                            </tbody>
                        </table>                       
                     </div>
                </div>
            </div>
        </section>      
      );  
    }
  }
